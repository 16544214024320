import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'

const PtitParvis = () => {
  const metaTitle = 'Famille | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, baie-saint-paul, famille'

  return (
    <Wolflayout mobileTitle={'Infos'}>
        <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
        <InfosNavigation />
        <InfosHeader title="P'tit Parvis" />
        <div className="container content pb-12">

          {/* TODO */}

        </div>
    </Wolflayout>
  )
}

export default PtitParvis
